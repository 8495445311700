// components/ChatbotForm.js
import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';

function ChatbotForm({
  chatbotName,
  setChatbotName,
  chatbotDescription,
  setChatbotDescription,
  chatbotInstructions,
  setChatbotInstructions
}) {
  return (
    <Form>
        <h1>Create Chatbot</h1>
        <p>
            Set the basic details for your chatbot. You can create up to <strong>3 chatbots</strong> 
        </p>
        
      <Form.Group as={Row} controlId="formChatbotName">
        <Form.Label column sm="4">
          Name
        </Form.Label>
        <Col sm="8">
          <Form.Control
            type="text"
            placeholder="Enter chatbot name"
            value={chatbotName}
            onChange={(e) => setChatbotName(e.target.value)}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formChatbotDescription">
        <Form.Label column sm="4">
          Description
        </Form.Label>
        <Col sm="8">
          <Form.Control
            type="text"
            placeholder="Add a short description"
            value={chatbotDescription}
            onChange={(e) => setChatbotDescription(e.target.value)}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formChatbotInstructions">
        <Form.Label column sm="4">
          Instructions
        </Form.Label>
        <Col sm="8">
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="How does this chatbot behave?"
            value={chatbotInstructions}
            onChange={(e) => setChatbotInstructions(e.target.value)}
          />
        </Col>
      </Form.Group>

      {/* <Button variant="primary" type="submit" disabled={isUpdating}>
        {selectedAssistantId === ''
          ? 'Create Assistant'
          : isUpdating
          ? 'Updating...'
          : 'Update Assistant'}
      </Button> */}
    </Form>
  );
}

export default ChatbotForm;
