// src/firebase.js
import { initializeApp } from 'firebase/app';
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
    apiKey: "AIzaSyAJJNMKxej1TO7AvU5Lwk5vP-tfHafQJwA",
    // authDomain: "cpipe-2a388.firebaseapp.com",
    authDomain: "luplab.com",
    projectId: "cpipe-2a388",
    storageBucket: "cpipe-2a388.appspot.com",
    messagingSenderId: "154402253759",
    appId: "1:154402253759:web:c95feac8eb5fd8f5094f0a",
    measurementId: "G-3W84MHSCM8"
  };

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Initialize Firebase Authentication and Firestore
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };




