export const transcribeVideos = async (videoDescriptors, onTranscriptionComplete) => {
    console.log('Entering transcribeVideos function');
    console.log('Received videoDescriptors:', JSON.stringify(videoDescriptors, null, 2));

    const transcriptions = [];

    for (const descriptor of videoDescriptors) {
        console.log('Transcribing video:', descriptor.value);

        let retryCount = 0;
        const maxRetries = 3;
        let success = false;

        while (retryCount < maxRetries && !success) {
            try {
                const response = await fetch('https://transcribevideo-snfypntodq-uc.a.run.app/', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    mode: 'cors', // Set the mode to handle CORS
                    body: JSON.stringify({ videos: [{ type: 'storage', value: descriptor.value }] }),
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    console.error(`Failed to transcribe video ${descriptor.value}. Server response: ${errorText}`);
                    throw new Error(`Failed to transcribe video. Server response: ${errorText}`);
                }

                const result = await response.json();
                console.log(`Transcription result for video ${descriptor.value}:`, result.transcriptions);

                // Push transcription result (assuming it's a single transcription string)
                transcriptions.push(result.transcriptions[0]);

                if (onTranscriptionComplete) {
                    await onTranscriptionComplete(descriptor);
                }

                success = true; // Mark as successful if we reach here
            } catch (error) {
                console.error(`Error transcribing video ${descriptor.value} (attempt ${retryCount + 1}):`, error);

                if (retryCount < maxRetries - 1) {
                    console.log(`Retrying transcription for ${descriptor.value}...`);
                } else {
                    throw error; // Re-throw the error if we've hit the retry limit
                }
                retryCount++;
            }
        }
    }

    return transcriptions;
};
