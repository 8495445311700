import React from 'react';
import { ListGroup, Container } from 'react-bootstrap';
import './AssistantsSidebar.css';

const AssistantsSidebar = ({ assistants, onSelectAssistant }) => {
  return (
    <Container fluid="md" className="assistants-sidebar-container">
      <h5 className="mt-3 mb-3 assistant-list-title">Your Chatbots</h5>
      <ListGroup variant="flush">
        {assistants.map((assistant) => (
          <ListGroup.Item
            key={assistant.id}
            action
            onClick={() => onSelectAssistant(assistant)}
            className="assistant-list-item"
          >
            {assistant.name}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  );
};

export default AssistantsSidebar;
