// utils/firebaseUtils.js
import { getStorage, ref, uploadBytesResumable, deleteObject } from 'firebase/storage';
import { getFirestore, collection, addDoc, serverTimestamp, query, where, orderBy, onSnapshot, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { sanitizeFileName } from './helperUtils';


export const uploadVideosToStorage = async (videos, user, setUploadProgress) => {
  if (!user) {
    alert('You must be logged in to upload videos.');
    return [];
  }

  const storage = getStorage();
  const filePaths = [];

  const uploadPromises = videos.map((video) => {
    const sanitizedFileName = sanitizeFileName(video.name);
    const timestamp = Date.now();
    const filePath = `videos/${user.uid}/${sanitizedFileName}_${timestamp}`;
    const storageRef = ref(storage, filePath);

    const uploadTask = uploadBytesResumable(storageRef, video, {
      contentType: video.type || 'video/mp4',
    });

    filePaths.push(filePath);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress((prev) => ({
            ...prev,
            [video.name]: progress,
          }));
        },
        (error) => {
          console.error('Upload error:', error);
          reject(error);
        },
        () => {
          resolve();
        }
      );
    });
  });

  await Promise.all(uploadPromises);
  return filePaths;
};

export const createChatroom = async (assistantId, user, db) => {
  try {
    const chatroomsRef = collection(db, 'chatrooms');
    const chatroomQuery = query(
      chatroomsRef,
      where('userID', '==', user.uid),
      where('chatbotID', '==', assistantId)
    );

    const querySnapshot = await getDocs(chatroomQuery);
    let chatroomID;

    if (querySnapshot.empty) {
      const newChatroom = {
        userID: user.uid,
        chatbotID: assistantId,
        createdAt: serverTimestamp(),
      };

      const chatroomDocRef = await addDoc(chatroomsRef, newChatroom);
      chatroomID = chatroomDocRef.id;
    } else {
      chatroomID = querySnapshot.docs[0].id;
    }

    return chatroomID;
  } catch (error) {
    console.error('Error creating chatroom:', error);
    alert('Failed to create chatroom. Please try again.');
    return null;
  }
};

// Other Firebase utility functions...
export const fetchChatHistory = (chatroomID, setChatHistory, unsubscribeRef, db) => {
    if (!chatroomID) {
      setChatHistory([]);
      return;
    }
  
    if (unsubscribeRef.current) {
      // Unsubscribe from previous listener
      unsubscribeRef.current();
    }
  
    const messagesRef = collection(db, 'messages');
    const messagesQuery = query(
      messagesRef,
      where('chatroomID', '==', chatroomID),
      orderBy('createdAt', 'asc')
    );
  
    const unsubscribe = onSnapshot(
      messagesQuery,
      (snapshot) => {
        const messages = [];
        snapshot.forEach((docSnap) => {
          const data = docSnap.data();
          messages.push({
            id: docSnap.id,
            text: data.text,
            sender: data.userID ? 'user' : 'bot',
            createdAt: data.createdAt ? data.createdAt.toDate() : new Date(),
          });
        });
        setChatHistory(messages);
      },
      (error) => {
        console.error('Error fetching chat history:', error);
        alert('Failed to load chat history. Please try again later.');
      }
    );
  
    unsubscribeRef.current = unsubscribe;
  };
  

export const deleteVideoFromStorage = async (filePath) => {
  const storage = getStorage();
  const fileRef = ref(storage, filePath);
  try {
    await deleteObject(fileRef);
    console.log(`Deleted video from storage: ${filePath}`);
  } catch (error) {
    console.error(`Error deleting video from storage: ${error}`);
  }
};
