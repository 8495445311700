// components/KnowledgeSection.js
import React from 'react';
import { Form } from 'react-bootstrap';
import VideoList from './VideoList';
import InstagramVideoSection from './InstagramVideoSection';

function KnowledgeSection({
  localVideos,
  handleLocalVideoUpload,
  selectedVideos,
  handleVideoSelect,
  uploadProgress,
  authenticated,
  setAuthenticated,
  videos,
  setVideos,
}) {
  return (
    <div>
      <h1>Add Knowledge</h1>
        <p>
            You can upload up to <strong>3 videos</strong> per chatbot<br></br>
        </p>
        <ul>
            <li>The videos will be used by the chatbot to generate responses</li>
            <li>Each video must be under <strong>100 MB</strong> and <strong>5 minutes</strong></li>
            <li>Please ensure your videos are in MP4 format and under the size limit</li>
        </ul>

        <p>
            If you want to create >3 chatbots or upload >3 videos, contact <a href="mailto:david@luplab.com">david@luplab.com</a>
        </p>

      {/* Local Video Upload */}
      <Form.Group controlId="formLocalVideos">
        <Form.Control
          type="file"
          accept="video/*"
          multiple
          onChange={handleLocalVideoUpload}
        />
      </Form.Group>

      {/* Display Uploaded Local Videos */}
      {localVideos.length > 0 && (
        <VideoList
          videos={localVideos}
          selectedVideos={selectedVideos}
          handleVideoSelect={handleVideoSelect}
          uploadProgress={uploadProgress}
          disabled={selectedVideos.length >= 3}
          onChange={handleLocalVideoUpload}
        />
      )}

      {/* Instagram Video Section */}
      {/* <InstagramVideoSection
        authenticated={authenticated}
        setAuthenticated={setAuthenticated}
        videos={videos}
        setVideos={setVideos}
        selectedVideos={selectedVideos}
        handleVideoSelect={handleVideoSelect}
      /> */}
    </div>
  );
}

export default KnowledgeSection;

