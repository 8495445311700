// components/AssistantSelection.js
import React from 'react';
import { Form } from 'react-bootstrap';

function AssistantSelection({ selectedAssistantId, handleAssistantSelect, userAssistants }) {
  return (
    <Form.Group controlId="formAssistantSelect">
      <Form.Label>Select Assistant</Form.Label>
      <Form.Control as="select" value={selectedAssistantId} onChange={handleAssistantSelect}>
        <option value="">Create New Assistant</option>
        {userAssistants.map((assistant) => (
          <option key={assistant.id} value={assistant.id}>
            {assistant.name}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
}

export default AssistantSelection;
