// src/App.js
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Chat from './components/chat/Chat';
import Create from './components/create/Create';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Login from './components/authentication/Login';
import { AuthProvider, AuthContext } from './components/authentication/AuthContext';
import PrivacyPolicy from './components/privacypolicy/PrivacyPolicy';
import './App.css'; // Import global styles

// Wrapper to protect routes that require authentication
const RequireAuth = ({ children }) => {
  const { user, loading } = React.useContext(AuthContext);

  if (loading) {
    return <p>Loading...</p>;
  }

  return user && !user.isAnonymous ? (
    children
  ) : (
    <Navigate to="/login" replace />
  );
};

function App() {
  return (
    <AuthProvider>
      <div className="App">
        {/* Header */}
        <Header />

        {/* Main Content */}
        <main className="App-content">
          <Routes>
            {/* Public Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/" element={<Chat />} />

            {/* Private Routes */}
            <Route
              path="/create"
              element={
                <RequireAuth>
                  <Create />
                </RequireAuth>
              }
            />
          </Routes>
        </main>

        {/* Footer */}
        <Footer />
      </div>
    </AuthProvider>
  );
}

export default App;

