// components/VideoList.js
import React from 'react';
import { Table, Form, ProgressBar } from 'react-bootstrap';

function VideoList({ videos, selectedVideos, handleVideoSelect, uploadProgress }) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Filename</th>
          <th>Size</th>
          <th>Select</th>
          <th>Upload Progress</th>
        </tr>
      </thead>
      <tbody>
        {videos.map((video, index) => (
          <tr key={index}>
            <td>{video.name}</td>
            <td>{(video.size / (1024 * 1024)).toFixed(2)} MB</td>
            <td>
              <Form.Check
                type="checkbox"
                checked={selectedVideos.includes(video)}
                onChange={() => handleVideoSelect(video)}
              />
            </td>
            <td>
              {uploadProgress[video.name] ? (
                <ProgressBar
                  now={uploadProgress[video.name]}
                  label={`${uploadProgress[video.name].toFixed(0)}%`}
                />
              ) : (
                'Not uploaded'
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default VideoList;
