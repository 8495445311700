// Create.js

import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AssistantSelection from './AssistantSelection';
import ChatbotForm from './ChatbotForm';
import KnowledgeSection from './KnowledgeSection';
import ChatWindow from '../chat/ChatWindow';
import {
  uploadVideosToStorage,
  createChatroom,
  fetchChatHistory,
  deleteVideoFromStorage
} from '../../utils/firebaseUtils';
import { transcribeVideos } from '../../utils/apiUtils';
import {
  createAssistantInOpenAI,
  updateAssistantInOpenAI,
} from '../../utils/openaiUtils';
import {
  saveAssistantToFirestore,
  updateAssistantInFirestore,
} from '../../utils/firestoreUtils';
import './Create.css';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, collection, query, where, onSnapshot } from 'firebase/firestore';
import { sanitizeFileName } from '../../utils/helperUtils';
import CreateAssistantButton from './createAssistantButton';


function Create() {
  // State variables
  const [authenticated, setAuthenticated] = useState(false);
  const [videos, setVideos] = useState([]);
  const [localVideos, setLocalVideos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [chatbotName, setChatbotName] = useState('');
  const [chatbotDescription, setChatbotDescription] = useState('');
  const [chatbotInstructions, setChatbotInstructions] = useState('');
  const [assistantCreated, setAssistantCreated] = useState(false);
  const [assistantId, setAssistantId] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [user, setUser] = useState(null);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [userAssistants, setUserAssistants] = useState([]);
  const [selectedAssistantId, setSelectedAssistantId] = useState('');
  const [prevSelectedVideos, setPrevSelectedVideos] = useState([]);
  const [assistantTranscriptions, setAssistantTranscriptions] = useState([]);

  // Initialize Firebase Auth and Firestore
  const auth = getAuth();
  const db = getFirestore();
  const unsubscribeRef = useRef(null);

  const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100 MB

  // useEffect hooks
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, [auth]);

  // Fetch user's assistants
  useEffect(() => {
    if (!user) return;

    const assistantsQuery = query(
      collection(db, 'chatbots'),
      where('userID', '==', user.uid)
    );

    const unsubscribe = onSnapshot(
      assistantsQuery,
      (snapshot) => {
        const userAssistants = [];
        snapshot.forEach((docSnap) => {
          userAssistants.push({ id: docSnap.id, ...docSnap.data() });
        });
        setUserAssistants(userAssistants);
      },
      (error) => {
        console.error('Error fetching user assistants:', error);
        alert('Failed to load your assistants. Please try again later.');
      }
    );

    return () => unsubscribe();
  }, [user, db]);

  // Clean up the chat history listener when component unmounts
  useEffect(() => {
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
    };
  }, []);

  const handleVideoSelect = (video) => {
    setSelectedVideos((prev) => {
      const exists = prev.some((v) =>
        v.id ? v.id === video.id : v.name === video.name
      );
      if (exists) {
        // If the video is already selected, deselect it
        return prev.filter((v) =>
          v.id ? v.id !== video.id : v.name !== video.name
        );
      } else {
        if (prev.length >= 3) {
          alert('You can only select up to 3 videos.');
          return prev;
        } else {
          return [...prev, video];
        }
      }
    });
  };
  

  // Create.js
const handleCreateOrUpdate = () => {
  if (selectedAssistantId === '') {
    // Create new assistant
    createAssistant();
  } else {
    // Update existing assistant
    updateAssistant();
  }
};

  const handleLocalVideoUpload = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = [];

    // Check if adding these files will exceed the limit
    setSelectedVideos((prev) => {
      const totalVideos = prev.length + files.length;
      if (totalVideos > 3) {
        alert('You can only select up to 3 videos.');
        const availableSlots = 3 - prev.length;
        const filesToAdd = files.slice(0, availableSlots);
        return [...prev, ...filesToAdd];
      } else {
        return [...prev, ...files];
      }
    });

    files.forEach((file) => {
      const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to MB
      console.log(`File "${file.name}" size: ${fileSizeMB.toFixed(2)} MB`);

      if (file.size <= MAX_FILE_SIZE) {
        validFiles.push(file);
      } else {
        alert(
          `File "${file.name}" exceeds the maximum size limit of ${
            MAX_FILE_SIZE / (1024 * 1024)
          } MB and will not be uploaded.`
        );
      }
    });

    setLocalVideos(validFiles);
    setSelectedVideos((prev) => [...prev, ...validFiles]);
  };

  // Handle selecting an assistant from the dropdown
  const handleAssistantSelect = async (e) => {
    const assistantId = e.target.value;
    setSelectedAssistantId(assistantId);

    if (assistantId === '') {
      // Create New Assistant selected
      // Clear the form fields
      setChatbotName('');
      setChatbotDescription('');
      setChatbotInstructions('');
      setSelectedVideos([]);
      setPrevSelectedVideos([]);
      setAssistantTranscriptions([]);
      setLocalVideos([]);
      setVideos([]);
      setAssistantCreated(false);
      setAssistantId('');
      setSelectedAssistant(null);
      setChatHistory([]);
    } else {
      // Existing assistant selected
      try {
        // Fetch assistant data from Firestore
        const assistantDocRef = doc(db, 'chatbots', assistantId);
        const assistantDocSnap = await getDoc(assistantDocRef);
        if (assistantDocSnap.exists()) {
          const assistantData = assistantDocSnap.data();
          // Set state with assistant data
          setChatbotName(assistantData.name || '');
          setChatbotDescription(assistantData.description || '');
          setChatbotInstructions(assistantData.instructions || '');
          setSelectedVideos(assistantData.selectedVideos || []);
          setPrevSelectedVideos(assistantData.selectedVideos || []);
          setAssistantTranscriptions(assistantData.transcriptions || []);
          setAssistantCreated(true);
          setAssistantId(assistantId);
          setSelectedAssistant({
            id: assistantId,
            name: assistantData.name,
            description: assistantData.description,
            instructions: assistantData.instructions,
            chatroomID: assistantData.chatroomID,
          });

          // Fetch chat history
          if (assistantData.chatroomID) {
            fetchChatHistory(
              assistantData.chatroomID,
              setChatHistory,
              unsubscribeRef,
              db
            );
          } else {
            // Create chatroom if it doesn't exist
            const chatroomID = await createChatroom(assistantId, user, db);
            setSelectedAssistant((prev) => ({
              ...prev,
              chatroomID,
            }));
            fetchChatHistory(chatroomID, setChatHistory, unsubscribeRef, db);
          }
        } else {
          console.error('Assistant not found in Firestore');
          alert('Assistant not found. Please try again.');
        }
      } catch (error) {
        console.error('Error fetching assistant data:', error);
        alert('Failed to fetch assistant data. Please try again.');
      }
    }
  };

  const createAssistant = async () => {
    if (!user) {
      alert('You must be logged in to create an assistant.');
      return;
    }

    if (userAssistants.length >= 3) {
      alert('You have reached the maximum number of assistants (3). Please delete an existing assistant to create a new one. Or contact david@luplab.com to create >3 chatbots.');
      return;
    }

    const combinedInstructions = `${chatbotDescription}\n${chatbotInstructions}`;
    console.log('Combined Instructions:', combinedInstructions);

    try {
      const videosToTranscribe = selectedVideos;

      if (videosToTranscribe.length === 0) {
        alert('No videos selected for transcription.');
        return;
      }

      const localVideoFiles = selectedVideos.filter(
        (video) => video instanceof File
      );
      const instagramVideos = selectedVideos.filter(
        (video) => video.media_url
      );

      // Upload local videos to Cloud Storage and get their file paths
      let localFilePaths = [];
      if (localVideoFiles.length > 0) {
        localFilePaths = await uploadVideosToStorage(
          localVideoFiles,
          user,
          setUploadProgress
        );
      }

      console.log('Local File Paths:', localFilePaths);

      // Prepare video descriptors
      const videoDescriptors = [];

      // Add local videos with type 'storage'
      localFilePaths.forEach((filePath) => {
        videoDescriptors.push({
          type: 'storage',
          value: filePath,
        });
      });

      console.log('Video Descriptors:', videoDescriptors);

      // Add Instagram videos with type 'url'
      instagramVideos.forEach((video) => {
        videoDescriptors.push({
          type: 'url',
          value: video.media_url,
        });
      });

      // Transcribe the videos
      // const transcriptions = await transcribeVideos(videoDescriptors);
      
      // Transcribe the videos
      const transcriptions = await transcribeVideos(
        videoDescriptors,
        async (descriptor) => {
          if (descriptor.type === 'storage') {
            // Delete the video from storage
            await deleteVideoFromStorage(descriptor.value);
          }
          // For URL-based videos, you may not need to do anything
        }
      );

      console.log('Transcriptions:', transcriptions);

      // Create the assistant with OpenAI
      const assistant = await createAssistantInOpenAI(
        chatbotName,
        combinedInstructions,
        transcriptions
      );
      setAssistantCreated(true);
      setAssistantId(assistant.id);

      console.log('Assistant created:', assistant);

      // Save assistant to Firestore
      await saveAssistantToFirestore(
        assistant.id,
        chatbotName,
        chatbotDescription,
        combinedInstructions,
        selectedVideos,
        transcriptions
      );

      // Create a chatroom
      const chatroomID = await createChatroom(assistant.id, user, db);

      // Set selectedAssistant
      setSelectedAssistant({
        id: assistant.id,
        name: chatbotName,
        description: chatbotDescription,
        instructions: combinedInstructions,
        chatroomID,
      });

      // Fetch chat history
      fetchChatHistory(chatroomID, setChatHistory, unsubscribeRef, db);

      // Reset the assistant selection to include the new assistant
      setSelectedAssistantId(assistant.id);
    } catch (error) {
      console.error('Error creating assistant:', error.message);
      alert(`Error creating assistant: ${error.message}`);
    }
  };

  const updateAssistant = async () => {
    setIsUpdating(true);
    const combinedInstructions = `${chatbotDescription}\n${chatbotInstructions}`;
    try {
      // Determine if there are any new videos
      const newVideos = selectedVideos.filter(
        (video) =>
          !prevSelectedVideos.some((prevVideo) =>
            prevVideo.id ? prevVideo.id === video.id : prevVideo.name === video.name
          )
      );

      // Transcribe new videos if any
      let newTranscriptions = [];
      if (newVideos.length > 0) {
        // Separate local videos and Instagram videos
        const localVideoFiles = newVideos.filter(
          (video) => video instanceof File
        );
        const instagramVideos = newVideos.filter(
          (video) => video.media_url
        );

        // Upload local videos to Cloud Storage and get their file paths
        let localFilePaths = [];
        if (localVideoFiles.length > 0) {
          localFilePaths = await uploadVideosToStorage(
            localVideoFiles,
            user,
            setUploadProgress
          );
        }

        // Prepare video descriptors
        const videoDescriptors = [];

        // Add local videos with type 'storage'
        localFilePaths.forEach((filePath) => {
          videoDescriptors.push({
            type: 'storage',
            value: filePath,
          });
        });

        // Add Instagram videos with type 'url'
        instagramVideos.forEach((video) => {
          videoDescriptors.push({
            type: 'url',
            value: video.media_url,
          });
        });

        // Transcribe the new videos
        // newTranscriptions = await transcribeVideos(videoDescriptors);

          // Transcribe the videos
        const newTranscriptions = await transcribeVideos(
          videoDescriptors,
          async (descriptor) => {
            if (descriptor.type === 'storage') {
              // Delete the video from storage
              await deleteVideoFromStorage(descriptor.value);
            }
            // For URL-based videos, you may not need to do anything
          }
        );

        console.log('New transcriptions:', newTranscriptions);
      }

      // Combine previous transcriptions with new transcriptions
      const combinedTranscriptions = [
        ...assistantTranscriptions,
        ...newTranscriptions,
      ];

      // Update assistant in OpenAI
      await updateAssistantInOpenAI(
        assistantId,
        chatbotName,
        combinedInstructions,
        combinedTranscriptions
      );

      // Update assistant in Firestore
      await updateAssistantInFirestore(
        assistantId,
        chatbotName,
        chatbotDescription,
        combinedInstructions,
        selectedVideos,
        combinedTranscriptions
      );

      // Update prevSelectedVideos and assistantTranscriptions
      setPrevSelectedVideos(selectedVideos);
      setAssistantTranscriptions(combinedTranscriptions);

      setIsUpdating(false);
    } catch (error) {
      console.error('Error updating assistant:', error.message);
      alert(`Error updating assistant: ${error.message}`);
      setIsUpdating(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedAssistantId === '') {
      // Create new assistant
      createAssistant();
    } else {
      // Update existing assistant
      updateAssistant();
    }
  };

  return (
    <Container fluid className="create-page-container">

      {/* Assistant Selection Dropdown */}
      <Row className="select-assistant-container">
        <Col className="select-assistant-dropdown">
          <AssistantSelection
            selectedAssistantId={selectedAssistantId}
            handleAssistantSelect={handleAssistantSelect}
            userAssistants={userAssistants}
          />
        </Col>
      </Row>
      <br />

      {/* Main Content */}
      <Row
        className="main-content-row d-flex align-items-stretch flex-grow-1"
        style={{ gap: '20px' }}
      >
        {/* Create/Update Chatbot Column */}
        <Col className="create-column flex-grow-1">
          <div className="scrollable-content">
          <ChatbotForm
          chatbotName={chatbotName}
          setChatbotName={setChatbotName}
          chatbotDescription={chatbotDescription}
          setChatbotDescription={setChatbotDescription}
          chatbotInstructions={chatbotInstructions}
          setChatbotInstructions={setChatbotInstructions}
        />

        <br />
        <KnowledgeSection
          localVideos={localVideos}
          handleLocalVideoUpload={handleLocalVideoUpload}
          selectedVideos={selectedVideos}
          handleVideoSelect={handleVideoSelect}
          uploadProgress={uploadProgress}
          authenticated={authenticated}
          setAuthenticated={setAuthenticated}
          videos={videos}
          setVideos={setVideos}
        />
        <br></br>
        <CreateAssistantButton
          handleCreateOrUpdate={handleCreateOrUpdate}
          isUpdating={isUpdating}
          selectedAssistantId={selectedAssistantId}
        />
          </div>
        </Col>

        {/* Chat Column */}
        <Col className="chat-column flex-grow-1">
          <div className="scrollable-content">
            <h1>Test Your Assistant</h1>
            <p>Once you create or select an existing chatbot, test it here</p>
            {assistantCreated && selectedAssistant && (
              <ChatWindow
                assistant={selectedAssistant}
                chatHistory={chatHistory}
                setChatHistory={setChatHistory}
              />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Create;

