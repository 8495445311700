import React from 'react';
import { Form, Button, InputGroup, Spinner } from 'react-bootstrap';
import './ChatInput.css';

const ChatInput = ({ message, setMessage, onSend, disabled, sending }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!disabled) {
      onSend();
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup>
        <Form.Control
          type="text"
          placeholder="Type your message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          disabled={sending} // Disable input while sending
        />
        <Button variant="primary" type="submit" disabled={disabled}>
          {sending ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Sending...</span>
            </>
          ) : (
            'Send'
          )}
        </Button>
      </InputGroup>
    </Form>
  );
};

export default ChatInput;
