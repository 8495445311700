// src/components/Header.js

import React, { useContext } from 'react';
import { Navbar, Nav, Container, Button, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AuthContext } from '../authentication/AuthContext';
import LogoutButton from '../authentication/LogoutButton';
import './Header.css';

function Header() {
  const { user } = useContext(AuthContext);

  return (
    <Navbar expand="lg" className="custom-navbar">
      <Container fluid>
        <Navbar.Brand href="/">Luplab</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto align-items-center">
            <LinkContainer to="/">
              <Nav.Link>Chat</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/create">
              <Nav.Link>Create</Nav.Link>
            </LinkContainer>
          </Nav>
          <Nav className="ms-auto align-items-center">
            {user && !user.isAnonymous ? (
              // If the user is logged in (not anonymous), show the email and LogoutButton component
              <>
                <Nav.Link disabled>{user.email}</Nav.Link>
                <LogoutButton />
              </>
            ) : (
              // If the user is not logged in or is anonymous, show the Login button
              <NavItem>
                <LinkContainer to="/login">
                  <Button
                    className="w-100 fw-bold login-button"
                  >
                    Login
                  </Button>
                </LinkContainer>
              </NavItem>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
