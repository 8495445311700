import React from 'react';
import { Container } from 'react-bootstrap'; // Import Container from Bootstrap

const PrivacyPolicy = () => {
    return (
        <Container className="privacy-policy">
            <br />
            <h1 className="text-center" style={{ color: '#ff6f61' }}>Privacy Policy</h1>
            <p>Last updated: October 15, 2024</p>
            
            <h2>Introduction</h2>
            <p>We at Luplab value your privacy. This policy outlines how we collect, use, and protect your personal information when you use our app, Luplab.</p>
            
            <h2>Information We Collect</h2>
            <p>
                - <strong>User Authentication Data</strong>: When you sign up using Facebook Login or Google, we collect your name, email address, and profile picture to create your user profile.<br />
                - <strong>Instagram Data</strong>: With your permission, we access your Instagram content (e.g., videos) to provide services within the app. We only access your data after you explicitly authorize it.
            </p>

            <h2>How We Use Your Information</h2>
            <p>
                - <strong>Authentication</strong>: To verify your identity and provide access to the app.<br />
                - <strong>Content Retrieval</strong>: To retrieve and analyze Instagram media for your use in the app.<br />
                - <strong>Service Improvement</strong>: To understand how our app is used and to improve its performance.
            </p>

            <h2>Data Security</h2>
            <p>We implement industry-standard security measures to protect your data. Access to your information is limited to authorized personnel and is secured with encryption and secure cloud storage.</p>

            <h2>Data Sharing</h2>
            <p>We do not sell or share your personal information with third parties except as required by law or for app functionality (e.g., using Instagram's API).</p>

            <h2>User Rights</h2>
            <p>You can revoke access to your Instagram data at any time by unlinking your Instagram account from the app.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions about this policy, please contact us at david@luplab.com</p>
            <br />
        </Container>
    );
};

export default PrivacyPolicy;
