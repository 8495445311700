// firestoreUtils.js
import { doc, setDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../firebase';  // Adjust path as needed

// export const saveAssistantToFirestore = async (assistantId, name, description, instructions, selectedVideos) => {
//   try {
//     const user = auth.currentUser;
//     if (!user) throw new Error('User is not authenticated');

//     const assistantDoc = doc(db, 'chatbots', assistantId);
//     await setDoc(assistantDoc, {
//       name,
//       description,
//       instructions,
//       image: '',
//       published: false,
//       status: 'draft',
//       hasDocuments: selectedVideos.length > 0,
//       chatrooms: [],
//       userID: user.uid,
//       createdAt: serverTimestamp(),
//     }, { merge: true });

//     console.log('Assistant saved to Firestore');
//   } catch (error) {
//     console.error('Error saving assistant to Firestore:', error.message);
//     throw error;
//   }
// };

export const saveAssistantToFirestore = async (
    assistantId, name, description, instructions, selectedVideos, transcriptions
  ) => {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error('User is not authenticated');
  
      const assistantDoc = doc(db, 'chatbots', assistantId);
      await setDoc(assistantDoc, {
        name,
        description,
        instructions,
        transcriptions, // Save transcriptions
        image: '',
        published: true,
        status: 'published',
        hasDocuments: selectedVideos.length > 0,
        chatrooms: [],
        userID: user.uid,
        createdAt: serverTimestamp(),
      }, { merge: true });
  
      console.log('Assistant saved to Firestore');
    } catch (error) {
      console.error('Error saving assistant to Firestore:', error.message);
      throw error;
    }
  };
  

export const updateAssistantInFirestore = async (assistantId, name, description, instructions, selectedVideos) => {
  try {
    const assistantDoc = doc(db, 'chatbots', assistantId);
    await updateDoc(assistantDoc, {
      name,
      description,
      instructions,
      hasDocuments: selectedVideos.length > 0,
    });
    console.log('Assistant updated in Firestore');
  } catch (error) {
    console.error('Error updating assistant:', error.message);
    throw error;
  }
};
