// src/components/chat/Chat.js
import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AssistantsSidebar from './AssistantsSidebar';
import ChatbotsSearch from './ChatbotsSearch';
import ChatWindow from './ChatWindow';
import { AuthContext } from '../authentication/AuthContext';
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  doc,
  getDoc,
  addDoc,
  serverTimestamp,
  orderBy,
} from 'firebase/firestore';
import './Chat.css';

const Chat = () => {
  const { user } = useContext(AuthContext);
  const db = getFirestore();
  const [assistants, setAssistants] = useState([]);
  const [availableChatbots, setAvailableChatbots] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);

  // Add loading state to handle initial authentication
  const [loading, setLoading] = useState(true);

  // Fetch user's chatrooms and associated chatbots
  useEffect(() => {
    let unsubscribe; // Declare unsubscribe variable to hold the listener

    if (user) {
      console.log('User is authenticated:', user);

      const chatroomsQuery = query(
        collection(db, 'chatrooms'),
        where('userID', '==', user.uid)
      );

      unsubscribe = onSnapshot(
        chatroomsQuery,
        async (snapshot) => {
          if (snapshot.empty || !snapshot.docs) {
            setAssistants([]);
            setLoading(false);
            return;
          }

          const assistantsData = [];

          // Fetch chatbot details for each chatroom
          const chatbotPromises = snapshot.docs.map(async (docSnap) => {
            const chatroomData = docSnap.data();
            const chatbotRef = doc(db, 'chatbots', chatroomData.chatbotID);
            const chatbotSnap = await getDoc(chatbotRef);
            if (chatbotSnap.exists()) {
              const chatbotData = chatbotSnap.data();
              return { id: chatbotSnap.id, ...chatbotData, chatroomID: docSnap.id };
            }
            return null;
          });

          const chatbots = await Promise.all(chatbotPromises);
          chatbots.forEach((chatbot) => {
            if (chatbot) {
              assistantsData.push(chatbot);
            }
          });

          setAssistants(assistantsData);
          setLoading(false); // Set loading to false after data is fetched
        },
        (error) => {
          console.error('Error fetching chatrooms:', error);
          alert('Failed to load chatrooms. Please try again later.');
          setLoading(false);
        }
      );
    } else {
      // User is not authenticated
      console.log('User is not authenticated.');
      setAssistants([]);
      setLoading(false);
    }

    // Cleanup function to unsubscribe from the listener when user changes or component unmounts
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user, db]);

  // Fetch all available (published) chatbots
  useEffect(() => {
    const chatbotsQuery = query(
      collection(db, 'chatbots'),
      where('published', '==', true)
    );

    const unsubscribe = onSnapshot(
      chatbotsQuery,
      (snapshot) => {
        const chatbots = [];
        snapshot.forEach((docSnap) => {
          chatbots.push({ id: docSnap.id, ...docSnap.data() });
        });
        setAvailableChatbots(chatbots);
        console.log('Available chatbots:', chatbots);
      },
      (error) => {
        console.error('Error fetching available chatbots:', error);
        alert('Failed to load available chatbots. Please try again later.');
      }
    );

    return () => unsubscribe();
  }, [db]);

  // Handle selecting an assistant from the sidebar
  const handleSelectAssistant = (assistant) => {
    setSelectedAssistant(assistant);
    // Fetch chat history for the selected assistant
    fetchChatHistory(assistant.chatroomID);
  };

  // Handle selecting a chatbot from the search
  const handleSelectChatbot = async (chatbot) => {
    if (!user) {
      alert('Please log in to interact with chatbots.');
      return;
    }
    try {
      // Check if a chatroom exists between the user and the chatbot
      const chatroomsRef = collection(db, 'chatrooms');
      const chatroomQuery = query(
        chatroomsRef,
        where('userID', '==', user.uid),
        where('chatbotID', '==', chatbot.id)
      );

      const querySnapshot = await getDocs(chatroomQuery);
      let chatroomID;

      if (querySnapshot.empty) {
        // No existing chatroom, create one
        const newChatroom = {
          userID: user.uid,
          chatbotID: chatbot.id,
          createdAt: serverTimestamp(),
        };

        const chatroomDocRef = await addDoc(chatroomsRef, newChatroom);
        chatroomID = chatroomDocRef.id;
      } else {
        // Existing chatroom found
        chatroomID = querySnapshot.docs[0].id;
      }

      // Fetch chat history
      fetchChatHistory(chatroomID);

      // Set the selected assistant with chatroomID
      setSelectedAssistant({ id: chatbot.id, ...chatbot, chatroomID });
    } catch (error) {
      console.error('Error selecting chatbot:', error);
      alert('Failed to select chatbot. Please try again.');
    }
  };

  // Function to fetch chat history for a given chatroomID
  const fetchChatHistory = (chatroomID) => {
    if (!chatroomID || !user) {
      setChatHistory([]);
      return;
    }

    const messagesRef = collection(db, 'messages');
    const messagesQuery = query(
      messagesRef,
      where('chatroomID', '==', chatroomID),
      orderBy('createdAt', 'asc')
    );

    const unsubscribe = onSnapshot(
      messagesQuery,
      (snapshot) => {
        if (!snapshot.docs) {
          setChatHistory([]);
          return;
        }

        const messages = [];
        snapshot.forEach((docSnap) => {
          const data = docSnap.data();
          messages.push({
            id: docSnap.id,
            text: data.text,
            sender: data.userID === user.uid ? 'user' : 'bot',
            createdAt: data.createdAt ? data.createdAt.toDate() : new Date(),
          });
        });
        setChatHistory(messages);
      },
      (error) => {
        console.error('Error fetching chat history:', error);
        alert('Failed to load chat history. Please try again later.');
      }
    );

    // Cleanup function if needed
    // You can store unsubscribe in state or a ref if you need to unsubscribe later
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="content-wrapper">
        <Container fluid className="chat-container">
          <Row className="flex-grow-1">
            {/* Assistants Sidebar */}
            <Col xs={12} md={3} lg={2} className="p-0 d-flex flex-column">
              <AssistantsSidebar
                assistants={assistants}
                onSelectAssistant={handleSelectAssistant}
              />
            </Col>

            {/* Chat Area */}
            <Col xs={12} md={9} lg={10} className="p-0 d-flex flex-column">
              {/* Chatbots Search Bar */}
              <Container fluid className="chat-search-window-container">
                <h2 className="chat-header">Send a message to your favorite content creator's AI chatbot</h2>
                <ChatbotsSearch
                  availableChatbots={availableChatbots}
                  onSelectChatbot={handleSelectChatbot}
                />

                {/* Chat Window */}
                <ChatWindow
                  assistant={selectedAssistant}
                  chatHistory={chatHistory}
                  setChatHistory={setChatHistory}
                  userID={user ? user.uid : null}
                  userDisplayName={
                    user
                      ? user.isAnonymous
                        ? 'Guest'
                        : user.displayName || 'User'
                      : 'Guest'
                  }
                />
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Chat;
