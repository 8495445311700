// openaiUtils.js

// openaiUtils.js
export const createAssistantInOpenAI = async (chatbotName, combinedInstructions, transcriptions) => {
    try {
      // Log transcriptions before processing
      console.log('Received transcriptions in createAssistantInOpenAI:', transcriptions);
  
      // Make sure transcriptions exist and are an array
      const documents = Array.isArray(transcriptions)
        ? transcriptions.map((transcription, index) => ({
            content: transcription,
            title: `Transcript Document ${index + 1}`, // Distinguish each transcript document
            type: 'text/plain',
          }))
        : [];
  
      // Prepare the payload with assistant details, transcriptions as documents, and enable file search
      const payload = {
        name: chatbotName,
        instructions: combinedInstructions,
        model: 'gpt-3.5-turbo',
        documents, // Attach the documents if any exist
        tools: [
          {
            type: 'file_search', // Activate file_search tool
          },
        ],
      };
  
      // Send a request to the backend API to create the assistant
      const response = await fetch('https://createassistant-snfypntodq-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      // Handle non-OK responses
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to create assistant: ${errorText}`);
      }
  
      const data = await response.json();
      return data.assistant; // Return the created assistant
    } catch (error) {
      console.error('Error creating assistant in OpenAI:', error.message);
      throw error;
    }
  };
    
  
  export const updateAssistantInOpenAI = async (assistantId, chatbotName, combinedInstructions, selectedVideos, transcriptions) => {
    try {
      console.log("transcriptions: ", transcriptions);
      const response = await fetch('https://updateassistant-snfypntodq-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        //createAssistant cloud functions receives name, instructions, model, tools, transcriptions
        body: JSON.stringify({
          assistantId,
          name: chatbotName,
          instructions: combinedInstructions,
          tools: selectedVideos,
          documents: transcriptions, // Include transcriptions when updating
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update assistant');
      }
  
      const data = await response.json();
      return data.assistant;
    } catch (error) {
      console.error('Error updating assistant in OpenAI:', error.message);
      throw error;
    }
  };


export const createThread = async () => {
    try {
      const response = await fetch('https://createthread-snfypntodq-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to create thread: ${errorText}`);
      }
  
      const data = await response.json();
      return data.thread;
    } catch (error) {
      console.error('Error creating thread:', error.message);
      throw error;
    }
  };
  
  export const addMessage = async (threadId, message) => {
    try {
      const response = await fetch('https://addmessage-snfypntodq-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ threadId, message }),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to add message: ${errorText}`);
      }
  
      const data = await response.json();
      return data.message;
    } catch (error) {
      console.error('Error adding message:', error.message);
      throw error;
    }
  };
  
    // openaiUtils.js
    export const createRun = async (threadId, assistantId) => {
        try {
        const response = await fetch('https://createrun-snfypntodq-uc.a.run.app/', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ threadId, assistantId }),
        });
    
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Failed to create run: ${errorText}`);
        }
    
        const data = await response.json();
        return data.assistantResponse;
        } catch (error) {
        console.error('Error creating run:', error.message);
        throw error;
        }
    };
  
  