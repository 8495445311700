// components/CreateAssistantButton.js
import React from 'react';
import { Button } from 'react-bootstrap';

function CreateAssistantButton({ handleCreateOrUpdate, isUpdating, selectedAssistantId }) {
  return (
    <Button variant="primary" onClick={handleCreateOrUpdate} disabled={isUpdating}>
      {selectedAssistantId === ''
        ? 'Create Assistant'
        : isUpdating
        ? 'Updating...'
        : 'Update Assistant'}
    </Button>
  );
}

export default CreateAssistantButton;
