// import React from 'react';
// import { ListGroup } from 'react-bootstrap';
// import MessageBubble from './MessageBubble';

// const MessageList = ({ messages }) => {
//   return (
//     <ListGroup className="w-100">
//       {messages.map((message, index) => (
//         <MessageBubble key={index} message={message} />
//       ))}
//     </ListGroup>
//   );
// };

// export default MessageList;


// src/components/chat/MessageList.js
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import './MessageList.css';

const MessageList = ({ messages }) => {
  return (
    <ListGroup>
      {messages.map((msg, index) => (
        <ListGroup.Item
          key={msg.id || index}  // Ensure a unique key is always provided
          className={`message-item ${msg.sender === 'bot' ? 'bot-message' : 'user-message'}`}
        >
          <strong>{msg.sender === 'bot' ? 'Bot' : 'You'}:</strong> {msg.text}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default MessageList;


